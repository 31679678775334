import React, { FC } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { App } from './App';
import RouterTester from './utils/TestPage';
import LandingPage from './components/LandingPage/LandingPage';
import TrackingClick from './components/TrackingClick/TrackingClick';
import TrackingQrScan from './components/TrackingQr/TrackingQrScan';
import CampaignPdf from './components/CampaignPdf/CampaignPdf';
import CampaingPdfError from './components/CampaignPdf/CampaignPdfError';
import UnsubscribePage from './components/Unsubscribe/UnsubscribePage';
import Config from './Config';

import './App.css';

const AppRoutes: FC = () => {
    return (
        <App>
            <BrowserRouter>
                <Routes>
                    {routes.map((route, i) => (
                        <Route key={i} path={route.path} element={<route.component />} />
                    ))}
                </Routes>
            </BrowserRouter>
        </App>
    );
};

const TrackOpenPixelComponent: FC = () => <div>TDB Need to do - Track Opening Pixel</div>;
const PageNotFoundComponent: FC = () => <div data-testid="page-not-found"></div>;

export const routes = [
    {
        path: '/tracking/lp',
        component: LandingPage,
        name: 'Landing Page',
    },
    {
        path: '/error',
        component: CampaingPdfError,
        name: 'Error',
    },
    {
        path: '/mailer/download/:encryptedString',
        component: CampaignPdf,
        name: 'Campaign',
    },
    {
        path: '/mailer/download',
        component: CampaignPdf,
        name: 'Campaign',
    },
    {
        path: '/unsubscribe/confirm',
        component: UnsubscribePage,
        name: 'Unsubscribe',
    },
    {
        path: '/tracking/open',
        component: TrackOpenPixelComponent,
        name: 'Open Pixel',
    },
    {
        path: '/tracking/qr',
        component: TrackingQrScan,
        name: 'QR Redirect',
    },
    {
        path: '/tracking/click',
        component: TrackingClick,
        name: 'Click',
    },
    {
        path: '/',
        component: PageNotFoundComponent,
        name: 'Home',
    },
    {
        path: '*',
        component: PageNotFoundComponent,
        name: 'PageNotFound',
    },
];

// add Tester depending on configuration
if (Config.SHOW_TEST) {
    routes.unshift({
        path: '/test',
        component: RouterTester,
        name: 'RouterTester',
    });
}

export default AppRoutes;
